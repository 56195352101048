<div class="select-employee-dialog">
  <h1 mat-dialog-title class="select-employee-dialog__title">
    {{ title }}
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div select-employee-dialog__content mat-dialog-content>
    <div class="description">
      <p>
        PROs of the shipper(s) not selected will be will be removed from the dispute but will be visible in the Excluded tab.
      </p>
    </div>

    <div class="form-controls">
      <div class="form-fields">
        <div *ngFor="let row of inspectors"class="width-50" floatLabel="always" data-test="lastName">
          <mat-radio-button [value]="row.inspectorFirstNm" (change)="inspectorSelected(row)"></mat-radio-button>
          <mat-label>{{ generateInspectorName(row[1].value) }}</mat-label>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="select-employee-dialog__footer">
    <button
      mat-flat-button
      xpoSmallButton xpoButton
      class="button-select"
      (click)="onSelectClick()"
      [disabled]="!selectedInspector"
      data-test="select"
    >
      Ok
    </button>
  </div>
</div>
