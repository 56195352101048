export enum InspectorHistoryDialogFormLabelsEnum {
  INSPECTOR = 'Inspector',
  SIC = 'SIC',
  AREA = 'Area',
  INSPECTION_DATE = 'Inspection Date',
  REASON = 'Reason',
  CANCEL = 'Cancel',
  OK = 'Ok',
  NOT_FOUND = 'No XPO employee was found with your search criteria. Please try a new search.',
  ERROR_API = 'An error happened.',
}
