export enum RouterUriComponents {
  DISPUTES = 'disputes',
  /**
   * Only for declaration in router module
   */
  CREATE_DISPUTE = 'create-dispute',
  /**
   * For use in navigation
   */
  DISPUTES_CREATE_DISPUTE = 'disputes/create-dispute',
  /**
   * Only for declaration in router module
   */
  VIEW_DISPUTE = 'view-dispute',
  /**
   * For use in navigation
   */
  DISPUTES_VIEW_DISPUTE = 'disputes/view-dispute',
  VIEW_DISPUTE_MOBILE = 'view-dispute-mobile',
  SHIPMENT_DETAIL = 'shipment-detail',
  SHIPMENT_DETAILS = 'shipment-details',
  DASHBOARD = 'dashboard',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
}

export enum RouterParams {
  DISPUTE_ID = 'dispute-id',
  DISPUTE_TYPE = 'dispute-type',
  WORKFLOW_ID = 'workflowItemInstId',
  SHIPMENT_ID = 'shipment-id',
}
