import { Injectable } from '@angular/core';

import {
  ActionCd,
  DisputeProValidationStatusCd,
  DisputeShipmentCurrencyCd,
  DisputeShipmentPaymentStatusCd,
} from '@xpo-ltl/sdk-common';
import { DisputesApiService, Shipment } from '@xpo-ltl/sdk-disputes';
import { XpoBoardData, XpoBoardDataSource, XpoBoardExportableDataSource, XpoBoardState } from '@xpo-ltl/ngx-board/core';

import moment from 'moment-timezone';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { CurrencyPipe } from '@angular/common';
import { RowShipment, RowShipmentErrors } from '../../classes/row-shipment';
import {
  CsvHelpers,
  HttpStatusCodesEnum,
  InvoiceDetailsErrorsEnum,
  InvoiceDetailsFieldNamesEnum,
  InvoiceDetailsFiltersEnum,
  InvoiceDetailsLabelNamesEnum,
} from '../../enums';
import { DisputeTypesEnum } from '../../enums/disputes/dispute-type.enum';
import { ProListColumn } from '../../interfaces/board/pro-list-column.interface';
import { HumanizePipe } from '../../pipes/humanize.pipe';
import { DisputesDataService } from './disputes-data.service';
import { DisputesShipmentsService } from './disputes-shipments.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceDetailsDataSourceService extends XpoBoardDataSource implements XpoBoardExportableDataSource {
  private errorOnFetch: boolean = false;
  private criteriaSubject = new BehaviorSubject<any>(undefined);
  criteria$ = this.criteriaSubject.asObservable();

  private rowsShipmentSubject = new BehaviorSubject<RowShipment[]>([]);
  rowsShipment$ = this.rowsShipmentSubject.asObservable();
  get rowsShipmentFiltered$(): Observable<RowShipment[]> {
    return this.criteria$.pipe(
      map((criteria) => this.rowsShipment.filter((shipment) => this.applyCriteria(shipment, criteria)))
    );
  }

  get rowsShipment(): RowShipment[] {
    return this.rowsShipmentSubject.value;
  }
  set rowsShipment(shipments: RowShipment[]) {
    this.rowsShipmentSubject.next(shipments);
  }

  private usdTotalAmountSubject = new BehaviorSubject<number>(0);
  usdTotalAmount$ = this.usdTotalAmountSubject.asObservable();

  get usdTotalAmount(): number {
    return this.usdTotalAmountSubject.value;
  }
  set usdTotalAmount(amount: number) {
    this.usdTotalAmountSubject.next(amount);
  }

  private cadTotalAmountSubject = new BehaviorSubject<number>(0);
  cadTotalAmount$ = this.cadTotalAmountSubject.asObservable();

  get cadTotalAmount(): number {
    return this.cadTotalAmountSubject.value;
  }
  set cadTotalAmount(amount: number) {
    this.cadTotalAmountSubject.next(amount);
  }

  get rowsShipmentSelected(): RowShipment[] {
    return this.rowsShipment.filter((row) => row.selected);
  }

  private columnsSubject = new BehaviorSubject<ProListColumn[]>(this.getAvailableColumns());
  columns$ = this.columnsSubject.asObservable();

  get columns(): ProListColumn[] {
    return this.columnsSubject.value;
  }
  set columns(value: ProListColumn[]) {
    this.columnsSubject.next(value);
  }

  private disputeTypeCdSubject = new BehaviorSubject<DisputeTypesEnum>(DisputeTypesEnum.OverchargeClaims);
  disputeTypeCd$ = this.disputeTypeCdSubject.asObservable();

  get disputeTypeCd(): DisputeTypesEnum {
    return this.disputeTypeCdSubject.value;
  }
  set disputeTypeCd(value: DisputeTypesEnum) {
    this.disputeTypeCdSubject.next(value);
  }

  private apiFilterAppliedSubject = new BehaviorSubject<boolean>(false);
  apiFilterApplied$ = this.apiFilterAppliedSubject.asObservable();

  get apiFilterApplied(): boolean {
    return this.apiFilterAppliedSubject.value;
  }
  set apiFilterApplied(value: boolean) {
    this.apiFilterAppliedSubject.next(value);
  }

  constructor(
    protected disputesDataService: DisputesDataService,
    protected disputesApiService: DisputesApiService,
    private disputesShipmentsService: DisputesShipmentsService
  ) {
    super();
  }

  calculateTotalDisputeAmountByCurrency(currencyCd: DisputeShipmentCurrencyCd) {
    if (!currencyCd) {
      return 0;
    }
    const amount: number = this.rowsShipment
      .filter((shipment) => {
        return shipment.currencyCd === currencyCd;
      })
      .map((shipment) => {
        return shipment.requestedAdjustmentDeltaAmount;
      })
      .reduce((prev, curr) => {
        return prev + curr;
      }, 0);

    if (currencyCd === DisputeShipmentCurrencyCd.USD) {
      this.usdTotalAmountSubject.next(amount);
    } else {
      this.cadTotalAmountSubject.next(amount);
    }
  }

  saveCriteria(criteria: any) {
    this.criteriaSubject.next(criteria);
  }

  /**
   * Check if shipmment match with criteria
   */
  applyCriteria(shipment: RowShipment, criteria: any): boolean {
    if (!criteria) {
      return true;
    }
    let checkQ = true;
    let checkStatus = true;
    let checkPickupDate = true;
    if (criteria[InvoiceDetailsFiltersEnum.Q] && !this.apiFilterApplied) {
      checkQ = JSON.stringify(shipment)
        .toLowerCase()
        .includes(criteria.q.toLowerCase());
    }
    const paymentStatusFilter: DisputeShipmentPaymentStatusCd[] = criteria[InvoiceDetailsFiltersEnum.PAYMENT_STATUS];
    if (paymentStatusFilter && paymentStatusFilter.length) {
      checkStatus = paymentStatusFilter.includes(shipment.paymentStatus);
    }
    const pickupDateFilter: Date = criteria[InvoiceDetailsFiltersEnum.PICKUP_DATE];
    if (pickupDateFilter) {
      const criteriaPickupDate = pickupDateFilter.getTime();
      const shipmentPickupDate = new Date(shipment.pickupDate).setHours(0, 0, 0);
      checkPickupDate = shipmentPickupDate === criteriaPickupDate;
    }
    return checkQ && checkStatus && checkPickupDate;
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData<any>> {
    return this.rowsShipment$.pipe(
      tap(() => this.saveCriteria(state.criteria)),
      map((shipments) => {
        return new XpoBoardData(state, shipments, shipments.length, this.pageSize);
      })
    );
  }

  private getCsvHeaderToExport() {
    let csvHeaderRow = '';
    this.getAvailableColumns().forEach((column) => {
      csvHeaderRow = csvHeaderRow + column.headerName + CsvHelpers.SEPARATOR;
    });
    csvHeaderRow += CsvHelpers.END_OF_LINE;
    return csvHeaderRow;
  }

  private getCsvContentToExport(shipments: RowShipment[]): string[] {
    const fields = this.getAvailableColumns().map((column) => column.field);
    return shipments.map((shipment) => {
      let csvRow = '';
      fields.forEach((field) => {
        const value = this.getCsvValue(shipment, field);
        csvRow += value + CsvHelpers.SEPARATOR;
      });
      csvRow += CsvHelpers.END_OF_LINE;
      return csvRow;
    });
  }

  private getCsvValue(shipment: RowShipment, field: string) {
    if (field === InvoiceDetailsFieldNamesEnum.INDICATORS) {
      let value = '';
      if (shipment.indicators) {
        if (shipment.indicators.ineligible) {
          value += `Ineligible: ${shipment.indicators.ineligibleReasons.replace(',', '-')}. `;
        }
        if (shipment.indicators.possibleDuplicate) {
          value += 'Possible Duplicate';
        }
      }
      return value;
    } else if (field === InvoiceDetailsFieldNamesEnum.PICKUP_DATE) {
      return moment(shipment.pickupDate).format('MM-DD-YYYY');
    } else {
      return shipment[field] || CsvHelpers.EMPTY_CELL;
    }
  }

  getCsvContentToExportForSavedDispute(disputeId: string): Observable<string[]> {
    return this.disputesApiService
      .listDisputeShipmentsAsCsv(
        {
          disputeId,
        },
        {
          proNbrs: [],
        },
        {
          loadingOverlayEnabled: false,
        }
      )
      .pipe(
        take(1),
        map((data) => {
          return [data.csvShipmentList];
        })
      );
  }

  exportForSavedDispute(disputeId: string, csvTitle: string): Observable<File> {
    let file: File;
    return this.getCsvContentToExportForSavedDispute(disputeId).pipe(
      take(1),
      map((csvContent) => {
        try {
          file = new File(csvContent, csvTitle, { type: CsvHelpers.FILE_TYPE });
        } catch {
          // for IE, doesn't support the file constructor
          // https://developer.mozilla.org/en-US/docs/Web/API/File/File
          file = new Blob(csvContent, { type: CsvHelpers.FILE_TYPE }) as File;
        }
        return file;
      })
    );
  }

  exportForUnsavedDispute(csvTitle: string): Observable<File> {
    let file: File;
    // Check for selected shipments
    const rowShipments = this.rowsShipmentSelected.length ? this.rowsShipmentSelected : this.rowsShipment;
    // Header
    const csvHeaderRow = this.getCsvHeaderToExport();
    // Content
    const csvContent: string[] = this.getCsvContentToExport(rowShipments);
    csvContent.unshift(csvHeaderRow);
    try {
      file = new File(csvContent, csvTitle, { type: CsvHelpers.FILE_TYPE });
    } catch {
      // for IE, doesn't support the file constructor
      // https://developer.mozilla.org/en-US/docs/Web/API/File/File
      file = new Blob(csvContent, { type: CsvHelpers.FILE_TYPE }) as File;
    }
    return of(file);
  }

  export(state: XpoBoardState): Observable<File> {
    const stringDate = moment(new Date()).format('MM-DD-YYYY_HH-mm');
    const csvTitle = `${this.disputeTypeCd}-${stringDate}.${CsvHelpers.EXTENSION}`;
    const disputeId =
      this.disputesDataService.dispute && this.disputesDataService.dispute.dispute.disputeId
        ? this.disputesDataService.dispute.dispute.disputeId
        : undefined;
    if (this.disputesDataService.dispute && this.disputesDataService.dispute.dispute.disputeId) {
      return this.exportForSavedDispute(disputeId, csvTitle);
    } else {
      return this.exportForUnsavedDispute(csvTitle);
    }
  }

  toggleErrorOnFetch(): void {
    this.errorOnFetch = !this.errorOnFetch;
  }

  /**
   * Add a new empty row in grid and refresh the board
   */
  addEmptyRow(): void {
    const shipment = this.getEmptyRow();
    this.addRow(shipment);
  }

  getEmptyRow() {
    const shipment = new RowShipment();
    shipment.proNbr = '';
    shipment.currencyCd = DisputeShipmentCurrencyCd.USD;
    shipment.listActionCd = ActionCd.ADD;
    shipment.requestedAdjustmentDeltaAmount = undefined;
    shipment.manuallyRateInd = true;
    shipment.validationStatusCd = DisputeProValidationStatusCd.NOT_VALIDATED;
    shipment.included = true;

    return shipment;
  }

  /**
   * Add a new row with data
   */
  addRow(shipment: RowShipment): void {
    const rows = this.rowsShipment.slice();
    const row = this.createRowFromShipment(shipment, rows.length);
    rows.push(row);
    this.rowsShipment = rows;
    this.refresh();
  }

  /**
   * Create new row shipment from a shipment
   */
  createRowFromShipment(shipment: Shipment, index: number): RowShipment {
    let rowShipment = new RowShipment();
    rowShipment = {
      ...shipment,
      idRow: index,
      selected: false,
      indicators: { error: false, possibleDuplicate: false, ineligible: false, ineligibleReasons: '' },
      requestedAdjustmentDeltaAmountFormatted: new CurrencyPipe('en').transform(
        shipment.requestedAdjustmentDeltaAmount
      ),
    };

    this.completeErrorsFromShipment(rowShipment, shipment, false);
    this.completeIndicatorInformationFromShipment(rowShipment, shipment);
    return rowShipment;
  }

  /**
   * Add multiple rows with data
   */
  addRows(shipments: Shipment[]): void {
    const rows = this.rowsShipment.slice();
    let row: RowShipment;
    shipments.forEach((shipment) => {
      row = this.createRowFromShipment(shipment, rows.length);
      rows.push(row);
    });
    this.rowsShipment = rows;
    this.refresh();
  }

  /**
   * Update selected items
   */
  selectItems(selectedRows: RowShipment[]): void {
    this.rowsShipment.forEach((row) => {
      row.selected = selectedRows.some((selectedRow) => selectedRow.idRow === row.idRow);
    });
  }

  /**
   * Remove list of shipments
   */
  removeRows(shipments: RowShipment[]): void {
    this.rowsShipment = this.rowsShipment.filter((shipment) => !shipments.some((s) => s.idRow === shipment.idRow));
    this.refresh();
  }

  resetRows(): void {
    this.rowsShipment = [];
  }

  /**
   * Grid columns for shipments
   */
  getAvailableColumns(): ProListColumn[] {
    return [
      {
        headerName: InvoiceDetailsLabelNamesEnum.PRO_NUMBER,
        field: InvoiceDetailsFieldNamesEnum.PRO_NUMBER,
        custom: true,
      },
      {
        headerName: InvoiceDetailsLabelNamesEnum.RELATED_CLAIM,
        field: InvoiceDetailsFieldNamesEnum.RELATED_CLAIM,
      },
      {
        headerName: InvoiceDetailsLabelNamesEnum.PAYMENT_STATUS,
        field: InvoiceDetailsFieldNamesEnum.PAYMENT_STATUS,
        suppressTabbing: true,
      },
      {
        headerName: InvoiceDetailsLabelNamesEnum.INVOICED_AMOUNT,
        field: InvoiceDetailsFieldNamesEnum.INVOICE_AMOUNT,
        suppressTabbing: true,
      },
      {
        headerName: InvoiceDetailsLabelNamesEnum.TOTAL_REQUESTED_ADJUSTED_AMOUNT,
        field: InvoiceDetailsFieldNamesEnum.REQUESTED_ADJUSTED_AMOUNT,
        custom: true,
      },
      {
        headerName: InvoiceDetailsLabelNamesEnum.CURRENT_INVOICE_AMOUNT,
        field: InvoiceDetailsFieldNamesEnum.CURRENT_INVOICE_AMOUNT,
        custom: true,
      },
      {
        headerName: InvoiceDetailsLabelNamesEnum.PICKUP_DATE,
        field: InvoiceDetailsFieldNamesEnum.PICKUP_DATE,
        suppressTabbing: true,
      },
      {
        headerName: InvoiceDetailsLabelNamesEnum.INDICATORS,
        field: InvoiceDetailsFieldNamesEnum.INDICATORS,
        suppressTabbing: true,
      },
      { headerName: InvoiceDetailsLabelNamesEnum.SHIPPER, field: InvoiceDetailsFieldNamesEnum.SHIPPER },
      { headerName: InvoiceDetailsLabelNamesEnum.BILL_TO, field: InvoiceDetailsFieldNamesEnum.BILL_TO },
      { headerName: InvoiceDetailsLabelNamesEnum.INSPECTOR, field: InvoiceDetailsFieldNamesEnum.INSPECTOR },
    ];
  }

  completeShipmentsInformation(shipments: Shipment[]) {
    const rowShipments = this.rowsShipment.slice();
    rowShipments.forEach((rowShipment) => {
      shipments.forEach((shipment) => {
        if (this.disputesShipmentsService.areProEquals(shipment, rowShipment)) {
          this.completeShipmentInformation(rowShipment, shipment);
        }
      });
    });
    this.rowsShipment = rowShipments;
  }

  private completeShipmentInformation(rowShipment: RowShipment, shipmentInfo: Shipment): void {
    const hasRecycle = shipmentInfo.shpmtRecycledPro && shipmentInfo.shpmtRecycledPro.length > 0;
    rowShipment.invoiceAmountAtTimeOfDisputeCreation = shipmentInfo.invoiceAmountAtTimeOfDisputeCreation;
    rowShipment.paymentStatus = shipmentInfo.paymentStatus;
    rowShipment.relatedObjectInd = shipmentInfo.relatedObjectInd;
    rowShipment.currencyCd = shipmentInfo.currencyCd;
    rowShipment.shpmtRecycledPro = shipmentInfo.shpmtRecycledPro;
    rowShipment.validationStatusCd = shipmentInfo.validationStatusCd;
    rowShipment.requestedAdjustmentDeltaAmount = shipmentInfo.requestedAdjustmentDeltaAmount;
    rowShipment.currentInvoiceAmount = shipmentInfo.currentInvoiceAmount;
    rowShipment.claimId = shipmentInfo.claimId;
    rowShipment.inspector = shipmentInfo['inspector'];

    if (hasRecycle) {
      rowShipment.shpmtRecycledPro.push({
        billToName: shipmentInfo.billToName,
        consigneeName: shipmentInfo.consigneeName,
        correlationId: shipmentInfo.correlationId,
        disputeId: shipmentInfo.disputeId,
        dspEventId: shipmentInfo.dspEventId,
        dspShpmtSeqNbr: shipmentInfo.seqNbr,
        finalDeliveryDt: shipmentInfo.finalDeliveryDt,
        listActionCd: shipmentInfo.listActionCd,
        pkupDt: shipmentInfo.pickupDate,
        proNbrTxt: shipmentInfo.proNbr,
        recordVersionNbr: shipmentInfo.recordVersionNbr,
        shipperName: shipmentInfo.shipperName,
        shpInstId: shipmentInfo.shipmentInstId,
        billStatCd: undefined,
        auditInfo: shipmentInfo.auditInfo,
      });
    } else {
      rowShipment.pickupDate = shipmentInfo.pickupDate;
      rowShipment.consigneeName = shipmentInfo.consigneeName;
      rowShipment.finalDeliveryDt = shipmentInfo.finalDeliveryDt;
      rowShipment.shipmentInstId = shipmentInfo.shipmentInstId;
      rowShipment.shipperName = shipmentInfo.shipperName;
      rowShipment.billToName = shipmentInfo.billToName;
    }

    this.completeErrorsFromShipment(rowShipment, shipmentInfo, hasRecycle);
    this.completeIndicatorInformationFromShipment(rowShipment, shipmentInfo);
  }

  private completeErrorsFromShipment(rowShipment: RowShipment, shipment: Shipment, hasRecycle: boolean) {
    if (!shipment || !shipment.shipmentInstId) {
      return;
    }
    const hasException = (exception) => {
      return shipment.shpmtValidationExcp.some((exc) => exc.errorCd === exception);
    };
    const errors: RowShipmentErrors = {};
    errors.needBeFixed = hasRecycle;

    if (shipment.shpmtValidationExcp && shipment.shpmtValidationExcp.length) {
      errors.notFound = shipment.shpmtValidationExcp.some(
        (exception) => exception.faultCd === HttpStatusCodesEnum.NOT_FOUND
      );
      errors.relatedClaimNotFound = hasException(InvoiceDetailsErrorsEnum.RelatedClaimNotFound);
      errors.multipleRelatedClaims = hasException(InvoiceDetailsErrorsEnum.MultipleRelatedClaims);
      errors.relatedClaimNotApproved = hasException(InvoiceDetailsErrorsEnum.RelatedClaimNotApproved);
      errors.relatedClaimWithPendingRebuttal = hasException(InvoiceDetailsErrorsEnum.RelatedClaimWithPendingRebuttal);
    }

    // Cleanup
    if (Object.values(errors).some((value) => value)) {
      rowShipment.errors = errors;
    } else {
      rowShipment.errors = undefined;
    }
  }

  private completeIndicatorInformationFromShipment(rowShipment: RowShipment, shipment: Shipment) {
    rowShipment.indicators = {};
    if (shipment.relatedObjectInd) {
      rowShipment.indicators.possibleDuplicate = true;
    }

    if (shipment.shpmtIneligibilityRsn && shipment.shpmtIneligibilityRsn.length) {
      rowShipment.indicators.ineligible = true;
      const humanize = new HumanizePipe();
      rowShipment.indicators.ineligibleReasons = shipment.shpmtIneligibilityRsn
        .map((item) => humanize.transform(item.ineligibilityReasonCd))
        .toString();
    }

    if (rowShipment.errors && rowShipment.errors.notFound) {
      rowShipment.indicators.error = true;
    }
  }
}
