<div class="inspection-history-dialog">
  <h1 mat-dialog-title class="inspection-history-dialog__title">
    {{ title }}
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div select-employee-dialog__content mat-dialog-content>
    <div class="feedback">
      <div *ngIf="loading">Loading ...</div>
      <div *ngIf="!dataSource?.data.length && employeesNotFound && !loading" data-test="error-message">
        {{ messageError }}
      </div>
    </div>
    <div class="table-box" [hidden]="loading || !(dataSource?.data.length > 0)">
      <div class="inspection-history-dialog__table_container">
        <table mat-table matSort [dataSource]="dataSource" class="inspection-history-dialog__table">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <mat-radio-button (change)="selectEmployee(row)" data-test="selectEmployee"></mat-radio-button>
            </td>
          </ng-container>
          <ng-container matColumnDef="inspector">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ InspectorHistoryDialogFormLabelsEnum.INSPECTOR }}
            </th>
            <td mat-cell *matCellDef="let row">{{ generateInspectorName(row.inspectionContext) }}</td>
          </ng-container>
          <ng-container matColumnDef="sic">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ InspectorHistoryDialogFormLabelsEnum.SIC }}</th>
            <td mat-cell *matCellDef="let row">{{ row.inspectionContext.inspectionSic }}</td>
          </ng-container>
          <ng-container matColumnDef="area">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ InspectorHistoryDialogFormLabelsEnum.AREA }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row.inspectionContext.area }}</td>
          </ng-container>
          <ng-container matColumnDef="lastUpdateDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ InspectorHistoryDialogFormLabelsEnum.INSPECTION_DATE }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row.lastUpdateDateTime | date:'MM/dd/yyyy' }}</td>
          </ng-container>
          <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ InspectorHistoryDialogFormLabelsEnum.REASON }}</th>
            <td mat-cell *matCellDef="let row">{{ row.reason }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="inspection-history-dialog__footer">
    <button mat-button (click)="onCancelClick()" data-test="cancel">
      {{ InspectorHistoryDialogFormLabelsEnum.CANCEL }}
    </button>
    <button
      mat-flat-button
      xpoSmallButton xpoButton
      (click)="onSelectClick()"
      class="button-select"
      data-test="ok">
      {{ InspectorHistoryDialogFormLabelsEnum.OK }}
    </button>
  </div>
</div>
