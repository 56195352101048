import { DisputePartySearchFilter, Party, SearchDisputePartyRqst } from '@xpo-ltl/sdk-disputes';
import {
  XrtAttributeFilter,
  XrtDisputePartySearchFilter,
  XrtDisputePartySearchRqst,
  XrtSearchQueryHeader,
} from '@xpo-ltl/sdk-elasticsearch';

export enum partyName {
  name1 = 'name1',
  addr1 = 'addr1',
  cityName = 'cityName',
  stateCd = 'stateCd',
  postalCd = 'postalCd',
  countryCd = 'countryCd',
}

export class DisputePartySearchHelper {
  static getRequestForXrtDisputePartySearch(party: Party, pageNumber: number): SearchDisputePartyRqst {
    const request = new SearchDisputePartyRqst();
   // const queryHeader = new XrtSearchQueryHeader();
    request.pageNumber = pageNumber;
    let searchFilter = new DisputePartySearchFilter();
    searchFilter = this.addFilter(searchFilter, party, partyName.name1);
    searchFilter = this.addFilter(searchFilter, party, partyName.addr1);
    searchFilter = this.addFilter(searchFilter, party, partyName.cityName);
    searchFilter = this.addFilter(searchFilter, party, partyName.stateCd);
    searchFilter = this.addFilter(searchFilter, party, partyName.postalCd);
    searchFilter = this.addFilter(searchFilter, party, partyName.countryCd);
   // request.header = queryHeader;
    request.filter = searchFilter;
    return request;
  }

  static addFilter(filter: DisputePartySearchFilter, party: Party, partyName: string): DisputePartySearchFilter {
    if (party && party[partyName]) {
      const nameFilter = new XrtAttributeFilter();
      nameFilter.startsWith = party[partyName];
      filter[partyName] = nameFilter;
    }
    return filter;
  }
}
