import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';

import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

import { SelectEmployeeDialogInterface } from '../../../../interfaces/disputes/select-employee-dialog.interface';
import { SelectEmployeeDialogService } from '../../../../services/disputes/select-employee-dialog.service';

import { BehaviorSubject } from 'rxjs';
import { InspectorHistoryDialogFormLabelsEnum } from 'src/app/enums/form-labels/inspector-history-dialog-form-labels.enum';

@Component({
  selector: 'app-inspector-history-dialog',
  templateUrl: './inspector-history-dialog.component.html',
  styleUrls: ['./inspector-history-dialog.component.scss'],
})
export class InspectorHistoryDialogComponent implements OnDestroy {
  readonly InspectorHistoryDialogFormLabelsEnum = InspectorHistoryDialogFormLabelsEnum;

  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  @ViewChild('lastName', { static: false })
  lastName: ElementRef;

  title: string;
  unsubscriber = new Unsubscriber();
  formGroup: FormGroup;
  dataSource = new MatTableDataSource<SelectEmployeeDialogInterface>([]);
  employeesNotFound = false;
  messageError: string;
  displayedColumns = [];
  selectedEmployee: SelectEmployeeDialogInterface = undefined;
  selectedInspector = undefined;
  previousInspectionDetails = []
  loading: boolean = false;

  get isValidForm(): boolean {
    return this.formGroup.valid;
  }

  totalPROs = 0;

  private isSuggestedSelectionActiveSubject = new BehaviorSubject<boolean>(true);
  isSuggestedSelectionActive$ = this.isSuggestedSelectionActiveSubject.asObservable();

  private isManualSelectionActiveSubject = new BehaviorSubject<boolean>(false);
  isManualSelectionActive$ = this.isManualSelectionActiveSubject.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<InspectorHistoryDialogComponent>,
    private selectEmployeeDialogService: SelectEmployeeDialogService,
    private snackBar: XpoSnackBar
  ) {
    this.title = this.data.hasOwnProperty('title') ? this.data.title : 'Search Employee';
    this.setData(this.data.hasOwnProperty('previousInspectionDetails') ? this.data.previousInspectionDetails : []);
    this.displayedColumns = this.getColumnsToDisplay();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSelectClick() {
    this.dialogRef.close();
  }

  getColumnsToDisplay(): string[] {
    return [
      'inspector',
      'sic',
      'area',
      'lastUpdateDateTime',
      'reason',
    ];
  }

  /**
   * Subscribe to non-Form observables (local and Service)
   */
  initSubscriptions() { }

  /**
   * Subscribe to Form control value and status changes
   */
  initWatchers() { }

  generateInspectorName(inspectionContext) {
    if (inspectionContext) {
      return `${inspectionContext.inspectorFirstNm} ${inspectionContext.inspectorLastNm}`;
    }
    return null;
  }

  setData(data) {
    if (data) {
      this.dataSource = new MatTableDataSource<any>(data);
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = new MatTableDataSource<any>([]);
      this.employeesNotFound = true;
    }
  }
}
