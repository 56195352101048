import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

export interface SelectedInspector {
  sicCode: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  isSuggested: boolean
}

@Component({
  selector: 'app-select-inspector-dialog',
  templateUrl: './select-inspector-dialog.component.html',
  styleUrls: ['./select-inspector-dialog.component.scss'],
})
export class SelectInspectorDialogComponent implements OnDestroy {
  title: string;
  unsubscriber = new Unsubscriber();
  messageError: string;
  selectedInspector: SelectedInspector;
  inspectors = []

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SelectInspectorDialogComponent>,
  ) {
    this.title = this.data.hasOwnProperty('title') ? this.data.title : 'Multiple Shippers';
    this.inspectors = this.data.hasOwnProperty('inspectorMap') ? Array.from(this.data.inspectorMap) : [];
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSelectClick() {
    this.dialogRef.close(this.selectedInspector);
  }

  /**
   * Subscribe to non-Form observables (local and Service)
   */
  initSubscriptions() { }

  /**
   * Subscribe to Form control value and status changes
   */
  initWatchers() { }

  inspectorSelected(row) {
    const employee = {
      sicCode: row[1].value.inspectionSic,
      employeeId: row[1].value.inspectorEmployeeId,
      firstName: row[1].value.inspectorFirstNm,
      lastName: row[1].value.inspectorLastNm,
      isSuggested: true
    }
    this.selectedInspector = employee;
  }

  generateInspectorName(inspectionContext) {
    if (inspectionContext) {
      return `${inspectionContext.inspectorFirstNm} ${inspectionContext.inspectorLastNm}`;
    }
    return null;
  }
}
